<template>
    <div class="collection-page">
        <div class="title">
            <div class="sub-title">收藏的简历</div>
        </div>
        <el-alert
            title="温馨提示： 请自觉保护求职者个人信息隐私"
            type="warning"
            show-icon>
        </el-alert>
        <el-card class="box-card" style="min-height:100%">
            <div v-if="state">
                <div v-if="collectResumesList.length>0">
                    <show-list :list="collectResumesList" @getbjitem="openFeedbackBox" @routeToresumeInfo="routeToresumeInfo" @deleteOneResume="deleteOneResume"></show-list>
                    <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
                </div>
                <div class="no" v-else>
                    <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                    <p>正在加载中...</p>
                </div>
            </div>
            <div class="no-data flex" v-else>
                <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                <p class="no-tips">亲爱的用户，目前还没有收藏求职者相关简历~~</p>
            </div>
        </el-card>
        <el-dialog
            title="标记简历"
            :before-close="handleFeedbackClose"
            :visible.sync="feedbackVisible"
            width="400px">
            <div style="margin-top:10px;">
                <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入标记内容"
                v-model="changeResumeStatus.message">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="feedbackVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveStatus">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import ShowList from '../components/ShowList'
import AppPagination from '../../../components/pagination/AppPagination'
import companyRequest from '../../../api/company'
export default {
    components: {
        ShowList,
        AppPagination
    },
    mounted() {
        this.collectResumesInfo()
    },
    data() {
        return {
            feedbackVisible: false,
            collectResumesList: [],
            state: true,
            total: 0,
            searchForm: {
                pageNum: 1,
                pageSize: 10,
                type: 2
            },
            changeResumeStatus: { // 改变简历状态
                id: null,
                status: 3,
                message:null,
            },
            percentage: '80%',
            isCollection: false //是否显示收藏按钮
        }
    },
    methods: {

        openFeedbackBox(row) {
            this.changeResumeStatus.id = row.id
            this.changeResumeStatus.message = row.message
            console.log('changeResumeStatus', this.changeResumeStatus)
            this.feedbackVisible = true
        },
        handleFeedbackClose() {
            this.changeResumeStatus.id = null
            this.changeResumeStatus.message = null
            this.feedbackVisible = false
        },
        //保存标记状态
        saveStatus() {

            //console.log('changeResumeStatus', this.changeResumeStatus)
            companyRequest.changeResumeStatus(this.changeResumeStatus).then((res) => {
                if (res.code === 200) {
                    this.$message.success('简历反馈成功~')
                    this.changeResumeStatus.id = null
                    this.changeResumeStatus.message = null
                    this.feedbackVisible = false
                    this.collectResumesInfo()
                }
            }).catch((err) => {
                this.$message.error(err)
                this.changeResumeStatus.id = null
                this.changeResumeStatus.message = null
                this.feedbackVisible = false
                this.collectResumesInfo()
            })
        },
        //查询收藏的简历
        collectResumesInfo() {
            companyRequest.collectResumesInfo(this.searchForm).then((res) => {
                if (res.code === 200) {
                     let list = res.data.list
                     this.total = res.data.total
                     list.forEach((item, index) => {
                        if(item.tag) {
                            item.tags = item.tag.split(',')
                        } else {
                            item.tags = []
                        }
                        // console.log('item', item)
                    })
                    this.collectResumesList = list
                    // this.collectResumesList = res.data.list
                    // this.collectResumesList.forEach((item, index) => {
                    //     item.tags = item.tag.split(',')
                    // })
                    if (this.collectResumesList.length === 0) {
                        this.state = false
                    }
                }
            }).catch((err) => {
                this.state = false
            })  
        },
        //查看简历详情 
        routeToresumeInfo(id) {
            this.$router.push({
                path: '/company/resumeInfo',
                query: {
                    id: id
                }
            })
        },
        //删除收藏的简历
        deleteOneResume(id) {
            // alert('删除')
            this.$confirm('此操作将永久删除该条收藏简历, 是否继续?', '删除简历', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    companyRequest.delCollectionResume({resumeId: id}).then((res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            })
                            this.searchForm.pageNum = 1
                            this.searchForm.pageSize = 10
                            this.collectResumesInfo()
                        }
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })          
            });
        },
        handleSize(size) {
            // console.log(11)
            this.searchForm.pageSize = size
            this.searchForm.pageNum = 1
            this.collectResumesInfo()
        },
        handleCurrent(num) {
            // console.log(22)
            this.searchForm.pageNum = num
            this.collectResumesInfo()
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .collection-page{
        height: 100%;
        .el-alert{
            padding: 15px;
            font-size: 14px !important;
        }
        .el-card{
            margin-top: 20px;
        }
        .no-data{
            margin: 150px 0 200px 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
</style>