<template>
    <div class="menu-center">
        <div class="number-table">
            <div class="number-table-item" v-for="(item) in list" :key="item.id">
                <div class="avata" v-if="item.gender==1">
                    <img  :src="item.heardImg||require('../../../assets/img/company/base_logo.png')"  />                        
                </div>
                <div class="avata" v-if="item.gender==2">
                    <img  :src="item.heardImg||require('../../../assets/img/company/headgirl.png')"  />                        
                </div>
                <div class="user-info">
                    <div class="user-info-wage" slot="reference">
                        <p class="name"> {{item.userName}} · {{item.gender===1?'男':'女'}} · {{item.age}}岁 · {{item.eductionName}}  </p>
                            <div class="tags-warpper">
                            <div class="tags">
                                <el-tag type="success" size="small" v-for="(row, index) in item.tags" :key="`row${index}`">{{row}}</el-tag>
                            </div>
                            <div class="no-tag">
                                求职状态: <span>{{item.nowStatusMessage}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="user-info">
                    <p class="name">semball 男 · 23岁 · 本科 · 江西上饶 </p>
                    <div class="tags-warpper">
                        <div class="tags">
                            <el-tag size="small">标签一</el-tag>
                            <el-tag type="success" size="small">标签二</el-tag>
                            <el-tag type="info" size="small">标签三</el-tag>
                            <el-tag type="warning" size="small">标签四</el-tag>
                            <el-tag type="danger" size="small">标签五</el-tag>
                        </div>
                        <div class="no-tag"></div>
                    </div>
                </div> -->
                <div class="position">
                    <div class="position-name">
                        {{item.industry}}<span class="split">|</span> 
                        <span class="position-name-desc">工作经验: {{item.workExperienceName}}</span>
                    </div>
                    <div class="position-time">
                        求职意向: 
                        <span class="position-time-desc">{{item.expectPost}}</span>
                    </div>
                </div>
                <div class="wage">
                    <div class="wage-info">期望薪资</div>
                    <div class="wage-number">{{item.salaryName}}</div>
                    <div class="wage-info" v-if="item.message!=null && item.message!=''" style="margin-top:8px;">简历标记：{{item.message}}</div>
                </div>
                <div class="download">
                    <el-button class="active" size="small" @click="routeTo(item.resumeId)">查看简历</el-button>
                    <el-button class="active" size="small" @click="openBj(item)">标记简历</el-button>
                    <el-button type="danger" size="small" @click="deleteResume(item.resumeId)">删除收藏</el-button>
                    <!-- <el-button class="success">查看简历</el-button>
                    <el-button class="active">下载简历</el-button> -->
                    <!-- <div class="success button border">查看简历</div>
                    <div class="active button border">下载简历</div>
                    <div class="collection button border" v-if="isCollection">收藏简历</div>
                    <div class="collection button border" v-if="isCollection">反馈简历</div> -->
                </div>
                <!-- <div class="download">
                    <el-button class="active">下载简历</el-button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        percentage: {
            type: String,
            default: '80%'
        },
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        isCollection: { //是否显示收藏按钮
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        }
    },
    methods: {
        openBj(row){
            this.$emit("getbjitem",row)
        },
        routeTo(id) {
            // console.log(id)
            this.$emit('routeToresumeInfo', id)
        },
        //删除收藏
        deleteResume(id) {
            // console.log(id)
            this.$emit('deleteOneResume', id)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .number-table{
        border: 1px solid #eee;
        margin-bottom: 20px;
      .number-table-item{
            padding: 20px 20px;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: flex-start;
            &:hover{
                background: #f6f6f6;
            }
        .avata{
            width: 10%;
            img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .user-info{
            width: 30%;
            display: flex;
            flex-direction: column;
            min-height: 60px;
            justify-content: space-between;
            .name{
                color: #333;
                font-size: 13px;
                font-weight: 700;
            }
            .tags-warpper{
                margin-top: 30px;
                .tags{
                    .el-tag{
                        margin-right: 10px;
                        margin-bottom: 5px;
                    }
                }
                .no-tag{
                    color: #555;
                    font-size: 12px;
                }
            }
        }
        .button{
            padding: 8px 12px;
        }
        .user-info-wage{
            display: flex;
            flex-direction: column;
            min-height: 60px;
            justify-content: space-between;
        }
        .resume{
            max-width: 500px;
            .done{
                font-size: 12px;
                color: #828282;
               justify-content: flex-end;
               .progress{
                   margin-left: 10px;
                   margin-top: 2px;
                   width: 100px;
                   height: 10px;
                   background: #e1e1e1;
                   .progress-do{
                       height: 100%;
                       background: $main;
                   }
               } 
            }
            .name{
                font-size: 16px;
                font-weight: 700;
                line-height: 46px;
                color: #282828;
                border-bottom: 1px solid #eee;
            }
            .hope-job{
                padding: 5px 0;
                border-bottom: 1px solid #eee;
                p{
                    font-size: 12px;
                    line-height: 20px;
                    color: #747474;
                }
            }
            .job-list{
                padding: 5px 0;
                border-bottom: 1px solid #eee;
              .tips{
                  color: #ff0522;
                  font-weight: 700;
              }
              .conpany{
                  padding-top: 10px;
                  .el-tag{
                      margin: 5px 10px 5px 0;
                  }
              }  
            }
        }
        .position{
            width: 30%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            min-height: 60px;
            .position-name{
                font-size: 14px;
                color: #111;
                .position-name-desc{
                    font-size: 14px;
                    color: #333;
                }
            }
            .split{
                color: #999;
                font-size: 12px;
                padding: 0 10px;
            }
            .position-time{
                font-size: 12px;
                color: #555;
                .position-time-desc{
                    color: $main;
                }
            }
        }
        .wage{
            width: 10%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-self: center;
            align-items: center;
            .wage-info{
                font-size: 12px;
                color: #555;
            }
            .wage-number{
                margin-top: 7px;
                letter-spacing: 2px;
                font-weight: 550;
            }
        }
        .download{
            width: 20%;
            box-sizing: border-box;
            display: flex;
            padding-left: 90px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div{
                cursor: pointer;
            }
            .el-button{
                margin-left: 0;
                margin-bottom: 5px;
            }
            // .el-button{
            //     width: 40%;
            //     &:hover{
            //         background: $hover;
            //     }
            // }
        }  
      }  
    }
</style>